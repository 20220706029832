import { Feature, GeneralFormat, Hero, NumberInstalled, Testimony, Work } from "./dummy.type"
import { CENTER } from "./maps.constants";

export const featuresProduct: Feature = {
    "title": "Our best features",
    "description": "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "data": [
        {
            "title": "Default Taiwindcss Config",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim fusce tortor, ac sed malesuada blandit. Et mi gravida sem feugiat."
        },
        {
            "title": "Fully Responsive Components",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim fusce tortor, ac sed malesuada blandit. Et mi gravida sem feugiat."
        },
        {
            "title": "RTL Languages Support",
            "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim fusce tortor, ac sed malesuada blandit. Et mi gravida sem feugiat."
        }
    ]
}

export const howProducWork: Work = {
    "title": "How our product work",
    "description": "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
    "data": [
        {
            "title": "Long Range",
            "description": "LoRa has a long connectivity range, with up to 5km range in highly populated areas and up to 15km range in rural areas.",
            "icon": "mat_solid:ev_station"
        },
        {
            "title": "Low Power Consumption",
            "description": "Allowing the operation to last up to 10 years on the same battery. Even for some cases, it is able to enter low-power sleep mode.",
            "icon": "mat_solid:ev_station"
        },
        {
            "title": "High Network Capacity",
            "description": "Supports millions of messages per base station, meeting the needs of public network operators serving large markets.",
            "icon": "mat_solid:ev_station"
        },
        {
            "title": "Cost Effective",
            "description": "Can reduce costs in 3 ways: reduce infrastructure costs, operational costs and sensors that have their own network.",
            "icon": "mat_solid:ev_station"
        },
        {
            "title": "Geolocation",
            "description": "This function allows us to detect the location of an object for free.",
            "icon": "mat_solid:ev_station"
        }
    ]
}

export interface ISolution {
    name: string;
    key: string;
    description: string;
    meta_data?: IMetaData;
}

export interface IMetaData {
    title: string;
    field: {
        title: string;
        key?: string;
        type: string;
        option?: string[];
        result?: any
    }[]
}

export const solutionData: GeneralFormat[] = [
    {
        name: "APJ Gate",
        key: "apj",
        icon: 'heroicons_solid:light-bulb',
        description: "EGATE as a Public Street Lighting monitoring solution that is easy to monitor, control and maintain",
    },
    {
        name: "Tower Gate",
        key: "tower",
        icon: 'mat_solid:grade',
        description: "BTS towers that are relatively difficult to reach can be more easily maintained with Evegate from a distance.",
    },
    {
        name: "Tokenless Recharge for Homes",
        key: "smarthouse",
        icon: 'mat_solid:home_work',
        description: "Top up home tokens from abroad? Teletoken can make the meter in your house connected to the internet",
    },
    {
        name: "EVCMS",
        key: "evcms",
        icon: 'mat_solid:ev_station',
        description: "Make your electric vehicle charging stations easier to monitor and report",
    },
    {
        name: "Home Charging",
        key: "homecharging",
        icon: 'mat_solid:electric_rickshaw',
        description: "Miliki pengecasan mobil listrik sendiri di rumah anda",
    },
    {
        name: "Others",
        key: "others",
        icon: 'mat_solid:electrical_services',
        description: "You have a brilliant idea in using our product",
    },
]

export const productData: GeneralFormat[] = [
    {
        name: "Egate",
        key: 'egate',
        icon: 'heroicons_solid:radio',
        description: "Egate is a smart device in the form of an MCB that is connected to the internet and allows you to monitor, control and maintain your electronic equipment easily.",
        imgUrl: "https://antares.id/_next/image?url=%2Fillustrations%2Fconnectivity.png&w=96&q=75"
    },
    {
        name: "Teletoken",
        key: 'teletoken',
        icon: 'mat_solid:generating_tokens',
        description: "Teletoken is a smart device that allows you to enter the token you have purchased into your home meter using only your mobile phone.",
        imgUrl: "https://antares.id/_next/image?url=%2Fillustrations%2Fplatform.png&w=96&q=75"
    },
    {
        name: "Charger Get",
        key: 'chargerget',
        icon: 'mat_solid:ev_station',
        description: "Charger get is a safe, convenient and easy to monitor electric vehicle AC charger.",
        imgUrl: "https://antares.id/_next/image?url=%2Fillustrations%2Fconnectivity.png&w=96&q=75"
    },
    {
        name: "EVController",
        key: 'evcontroller',
        icon: 'heroicons_solid:cpu-chip',
        description: "EVController is a specialized device designed to provide stable, real-time monitoring and control of electric vehicle chargers. Using Modbus for data acquisition, it ensures accurate and efficient management of charging operations.",
        imgUrl: "https://antares.id/_next/image?url=%2Fillustrations%2Fplatform.png&w=96&q=75"
    }
]

export const heroData: Hero = {
    title: "EVGATE",
    focus: "Ultimate",
    description: "EV gate is a connection between PLN electricity meters and EV Charger for collecting real-time data to be analyze!"
}

export const testimonyData: Testimony[] = [
    {
        name: "Leslie Alexander",
        role: "Freelance React Developer",
        quote: "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change."
    },
    {
        name: "Jacob Jones",
        role: "Digital Marketer",
        quote: "Simply the best. Better than all the rest. I'd recommend this product to beginners and advanced users."
    },
    {
        name: "Jenny Wilson",
        role: "Graphic Designer",
        quote: "I cannot believe that I have got a brand new landing page after getting Omega. It was super easy to adit and publish."
    }
]

export const installedData: NumberInstalled = {
    enterpriseClient: 100,
    connectedDevice: 55800,
    activeUser: 16830,
    yearOfExperience: 6
}

// export